<template>
  <div>
    <Header />
    <v-breadcrumbs
      divider="/"
      :items="breadcrumbs"
      class="breadcrumbs"
    ></v-breadcrumbs>
    <div class="center">
      <h3 class="eventLarge">Thank You!</h3>
      <p class="eventSub">
        You have been added to the 2023 Bison Roundup Waitlist
      </p>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../../components/Header.vue";
import $ from "jquery";
export default {
  name: "BisonRoundup",
  metaInfo() {
    return {
      title: "Antelope Island State Park Bison Roundup",
      meta: [
        {
          name: "description",
          content: "Range Ride Information and Registration",
        },
      ],
    };
  },
  components: {
    Header,
  },
  data() {
    return {
      collectionId: "273729257662",
      process: "start",
      checked: false,
      checked2: false,
      eventClosed: true,
      breadcrumbs: [],
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Antelope Island State Park",
        disabled: false,
        href: "/parks/UTANTE",
      },
      { text: "Bison Roundup", disabled: true },
    ];
  },
  methods: {
    checkChange: function (e) {
      var checkbox = document.getElementById("checkbox");
      if (checkbox.checked) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    redirect(x) {
      this.$router.push(x);
    },
    checkChange2: function (e) {
      var checkbox2 = document.getElementById("checkbox2");
      if (checkbox2.checked) {
        this.checked2 = true;
      } else {
        this.checked2 = false;
      }
    },
    sendMail() {
      window.open(
        `mailto:parksupport@parkspass.org?subject=Antelope Island - Bison Roundup Question&body= %0D%0A %0D%0A Sent from Parkspass.utah.org`
      );
    },
    continueClicked: function () {
      if (!this.checked || !this.checked2) {
        return;
      }
      this.loadShopifyCollection();
      this.process = "buynow";
    },
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.EveyShopifyBuyInit();
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.EveyShopifyBuyInit;
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
  },
};
</script>

<style scoped>
.half {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.left {
  width: 32vw;
}
.right {
  width: 32vw;
}
.center2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center2 > ul {
  width: 76vw;
}
.date-flex {
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
}
ul {
  margin-left: 40px;
}
li {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
}
.bold-p {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.camping > p {
  margin-bottom: 5px;
}
#weirdText {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.breaker {
  margin: 0px 20px;
}
h4 {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
h5 {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.time {
  width: 100px;
}
.time-des {
  width: 350px;
}
p {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
h3 {
  margin-top: 20px;
}
.bolds {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
a {
  color: #96a145;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-flex {
  display: flex;
  height: 280px;
  margin: 50px 0px;
}

h6 {
  color: rgba(0, 0, 0, 0.86);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 0.4px;
}
.bold {
  font-weight: bold;
}
.card-img {
  width: 425px;
}
.card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text-cont {
  padding: 20px;
}
.divider {
  stroke-width: 1px;
  stroke: #9f9f9f;
  margin: 50px 20px;
}
.reg-button {
  border-radius: 50px;
  border: 1px solid #96a145;
  background: #96a145;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 16px;
}

.button-cont {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  margin-right: 20px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.roundupImg {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: top;
  height: auto;
  position: relative;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.roundupBox p,
.roundupBox li,
.roundupBox li a,
.roundupBox a {
  font-size: 20px;
}
.roundupText {
  margin: 0px;
}
.roundupTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  background-color: whitesmoke;
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  background-color: whitesmoke;
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyBox,
.shopifyBox2 {
  background-color: white;
  display: inline-block;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.shopify-buy__product {
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.roundupBox p,
.roundupBox li,
.roundupBox li a,
.roundupBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  max-width: 1200px;
  text-align: left;
}
b {
  font-weight: bold;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145 !important;
}
.breadcrumbs {
  padding: 20px 0px 32px 0px;
}
.infoBox {
  margin-left: 32px;
}

@media only screen and (max-width: 1024px) {
  .bold-p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .half {
    flex-direction: column;
  }
  .left {
    width: 80vw;
  }
  .right {
    width: 80vw;
  }
  .center-img {
    display: flex;
    justify-content: center;
  }
  h4 {
    text-align: center;
  }
  .bold-p {
    width: 10vw;
  }
  .plength {
    width: 60vw;
  }
  .time-des {
    width: 60vw;
  }
  .right > h4 {
    margin-top: 20px;
  }
  .right > img {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .page-wrap {
    padding: 8px;
  }
  .bottom-left {
    padding: 16px 8px;
  }
  .shopifyContainer {
    display: block;
    padding: 0px;
    width: auto;
  }
  .breadcrumbs {
    padding-bottom: 8px;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 0px !important;
  }
  .roundupBox p,
  .roundupBox li,
  .roundupBox li a,
  .roundupBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
}
@media only screen and (max-width: 425px) {
  .card-flex {
    height: unset;
    flex-direction: column;
    width: 80vw;
  }
  .disapear {
    display: none;
  }
  h6 {
    color: rgba(0, 0, 0, 0.86);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 100% */
    letter-spacing: 0.4px;
  }
  .card-right {
    flex-direction: row;
    align-items: center;
  }
  .button-cont {
    display: block;
    margin: 20px;
  }
  .right > img {
    width: 80vw;
  }
  .left > img {
    width: 80vw;
  }
  .reg-button {
    border-radius: 50px;
    border: 1px solid #96a145;
    background: #96a145;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 11px;
    width: 110px;
  }
  .eventSub {
    padding-left: 10px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .plength {
    width: 40vw;
  }
  .bold-p {
    width: 25vw;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .reverse > .right > img {
    margin: 0px 0px 20px 0px;
  }
}
</style>
