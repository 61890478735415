<template>
  <v-app>
    <div class="header">
      <div class="header-cont">
        <img src="../../assets/images/parkspass-logo-2023.svg" alt="" />
        <h3 class="white-text">Check-In</h3>
      </div>
    </div>
    <div class="main-cont">
      <v-card class="card1">
        <img src="../../assets/images/error-check.svg" alt="" />
        <div class="down-flex">
          <h1>Error</h1>
          <h3 v-if="error_code == '404'">Order number not found</h3>
          <h3 v-else>Pass not valid for today</h3>
        </div>
      </v-card>
      <div class="button-flex">
        <button class="black-button" @click="again()">Try Again</button>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Error Check-In",
  metaInfo: {
    title: "Error Check-In",
    meta: [
      {
        name: "description",
        content: "Error Check-In",
      },
    ],
  },
  data() {
    return {
      info: false,
      error_code: "404",
      park_abbr: this.$route.params.park,
    };
  },
  methods: {
    again() {
      console.log("trying again");
      this.$router.push(`/${this.park_abbr}/checkin`);
    },
  },
};
</script>

<style scoped>
h1 {
  color: #212121;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
h2 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}
h3 {
  color: var(--material-theme-sys-light-on-primary-container, #222);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
h4 {
  color: #222;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.green-text {
  color: #96a145;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
}
button {
  margin-right: 8px;
}
.header {
  background: #2e2e31;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
}
.header-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.white-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.main-cont {
  margin: 20px;
}

#app {
  height: 100vh;
  background: #f5f5f5;
}
.card1 {
  padding: 16px !important;
  box-shadow: none !important;
  display: flex;
  align-items: flex-start;
  border-radius: 4px !important;
  border: 1px solid #410002 !important;
  background-color: #ffdad6 !important;
}
p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.black-button {
  border-radius: 29px;
  background: #2e2e31;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 8px 16px;
  margin-top: 16px;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.icon-help img {
  width: 24px;
  height: 24px;
}
#input-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-card {
  border-radius: 4px;
  background: #2e2e31 !important;
  padding: 14px;
}
.white-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
.underline {
  text-decoration-line: underline;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
.down-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
</style>
